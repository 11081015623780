import React, { useEffect, useState, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import includes from 'lodash/includes'
import { Link } from 'react-router-dom'
import { useAsync } from 'react-async'
import { omit } from 'lodash'
import { Alert, Col, Form, Input, InputNumber, Modal, Row, Select, Spin, Tooltip } from 'antd'
import { useAuth0 } from '../../auth0'
import { depositPropTypes, formPropTypes } from '../../types'
import { getRetailers } from '../retailers/api'
import { CategorySelector } from '../../components'
import { getFilteredPackageList } from './api'

const { Option } = Select
const { TextArea } = Input

const RetailerSelector = forwardRef((props, ref) => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()
  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    promiseFn: getRetailers,
  })

  if (error) {
    console.log(error)
    return <div>An error occurred while fetching retailers.</div>
  }

  if (!data)
    return null

  const dataValues = data ? orderBy(data, 'title', 'asc') : []

  return (
    <Select
      allowClear
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      loading={isLoading}
      mode="multiple"
      ref={ref}
      showSearch
      {...props}
    >
      {dataValues.map((element) =>
        <Option
          key={element.id}
          name="retailerOption"
          value={element.id}
        >
          {element.name}
        </Option>,
      )}
    </Select>
  )
})

const CurrencySelector = forwardRef(({ currencyList = [], ...props }, ref) => {
  const cleanedProps = omit(props, 'currencyList')

  return (
    <Select ref={ref} {...cleanedProps}>
      {currencyList?.sort().map((currency) =>
        <Option key={currency} name="depositCurrencyOption" value={currency}>{currency}</Option>,
      )}
    </Select>
  )})

CurrencySelector.propTypes = {
  currencyList: PropTypes.array,
}

const CountrySelector = forwardRef(({ countryList = [], ...props }, ref) => {
  const cleanedProps = omit(props, 'countryList')

  return (
    <Select ref={ref} showSearch {...cleanedProps}>
      {countryList?.sort().map((country) =>
        <Option key={country} name="depositCountryOption" value={country}>{country}</Option>,
      )}
    </Select>
  )})

CountrySelector.propTypes = {
  countryList: PropTypes.array,
}

const PackageSelector = forwardRef((props, ref) => {
  const [packages, setPackages] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const timerRef = useRef()

  useEffect(() => () => clearTimeout(timerRef.current), [])

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const getList = (nameOrSku) => {
    if (nameOrSku.length < 2) {
      setPackages([])

      return false
    }

    clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      setIsLoading(true)

      getFilteredPackageList({ accessTokenPromise, nameOrSku })
        .then(({ packages }) => setPackages(orderBy(packages, 'name', 'asc')))
        .finally(() => setIsLoading(false))
    }, 500)
  }

  const cleanedProps = omit(props, 'selectedPackageId')

  return (
    <Select
      disabled={!!props.selectedPackageId}
      filterOption={false}
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      onSearch={getList}
      ref={ref}
      showSearch
      {...cleanedProps}
    >
      {
        packages.map((element) =>
          <Option
            data-search={`${element.name} ${element?.brand?.name}`}
            key={element.id}
            name="depositPackageOption"
            value={element.id}
          >
            <Tooltip key={element.id} placement="right" title={`${element.name} ${element.brand && element.brand.name}`}>
              {element.name} {element.brand && `(${element.brand.name})`} [{element.sku}]
            </Tooltip>
          </Option>
        )}
    </Select>
  )
})

PackageSelector.propTypes = {
  selectedPackageId: PropTypes.number,
}

const ModalWithForm = ({
  countryList,
  currencyList,
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedDeposit,
  selectedPackageId,
  selectedRetailers=[],
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const { isFieldsTouched } = form
  const isUpdate = !!selectedDeposit

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fields = {
            ...form.getFieldsValue([
              'barcode_sorting_key',
              'package_id',
              'barcode_categories',
              'country',
              'currency',
              'net_amount',
              'tax_amount',
              'barcode',
              'product_name',
              'retailers',
              'description',
            ]),
          }

          const barcodeCategoryList = fields.barcode_categories?.map(category => ({
            id: category,
          }))

          const selectedRetailerIds = selectedRetailers.map(category => category.id)
          const removedRetailers = selectedRetailerIds.filter(retail => !includes(fields.retailers, retail)).map((retailer) => {
            return {
              _delete: true,
              id: retailer,
            }
          })
          const newRetailers = fields.retailers.filter(retail => !includes(selectedRetailerIds, retail)).map(retailer => {
            return {
              id: retailer,
            }
          })

          const finalRetailers = removedRetailers.concat(newRetailers)
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedDeposit.id : undefined

          onSubmit(actionType, { ...fields, barcode_categories: barcodeCategoryList, id, retailers: finalRetailers })
        })
        .catch((error) => handleError(error))
    } else {
      hideModal()
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelDepositModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateDepositSelenium' : 'addDepositSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update deposit' : 'Create deposit'}
    >
      <Form form={form} layout="vertical">
        {!isUpdate &&
          <div>
            <Form.Item
              initialValue={selectedPackageId}
              label="Package/SKU"
              name="package_id"
              rules={[{ message: 'Package is required!', required: true }]}
            >
              <PackageSelector selectedPackageId={selectedPackageId} />
            </Form.Item>

            <Form.Item
              label="Country"
              name="country"
              rules={[{ message: 'Country is required!', required: true }]}
            >
              <CountrySelector countryList={countryList} />
            </Form.Item>

            <Form.Item
              label="Currency"
              name="currency"
              rules={[{ message: 'Currency is required!', required: true }]}
            >
              <CurrencySelector currencyList={currencyList} />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Net price"
                  name="net_amount"
                  rules={[{ message: 'Net price is required!', required: true }]}
                >
                  <InputNumber max={100000} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Tax amount"
                  name="tax_amount"
                  rules={[{ message: 'VAT/TAX is required!', required: true }]}
                >
                  <InputNumber max={100000} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Product barcode"
              name="barcode"
              rules={[
                {
                  message: 'Product barcode is required!',
                  required: true,
                },
                {
                  message: 'Product barcode should contain only English letters and numbers!',
                  pattern: new RegExp('^[A-Za-z0-9]+$'),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Sorting key"
              name="barcode_sorting_key"
              rules={[{ message: 'Sorting key is required!', required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Product name"
              name="product_name"
              rules={[{ message: 'Product name is required!', required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        }

        {!isUpdate &&
          <Form.Item
            label="Categories"
            name="barcode_categories"
          >
            <CategorySelector />
          </Form.Item>
        }

        <Form.Item
          initialValue={(selectedRetailers ?? []).map(cat => cat.id)}
          label="Retailers"
          name="retailers"
        >
          <RetailerSelector />
        </Form.Item>
        <Form.Item
          initialValue={isUpdate ? selectedDeposit.description : undefined}
          label="Description"
          name="description"
        >
          <TextArea autoSize={{ maxRows: 5 , minRows: 3 }} />
        </Form.Item>
      </Form>

      {isUpdate &&
        <Alert
          description={
            <span>
              Add/remove barcodes on the
              <Link to={`/deposits/${selectedDeposit.id}`}> deposit details page</Link>
            </span>
          }
          message="Informational Notes"
          showIcon
          type="info"
        />
      }
      {
        errorMessage &&
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          type="error"
        />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  countryList: PropTypes.array,
  currencyList: PropTypes.array,
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedDeposit: depositPropTypes,
  selectedPackageId: PropTypes.number,
  selectedRetailers: PropTypes.array,
  setModalLoading: PropTypes.func.isRequired,
}

export default ModalWithForm
