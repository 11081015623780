import React from 'react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import {
  Alert,
  Form,
  Input,
  Modal,
} from 'antd'
import { barcodePropTypes, formPropTypes } from '../../types'
import { CategorySelector } from '../../components'

const BarcodeModalForm = ({
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedBarcode,
  selectedCategories = [],
  setVisibility,
  setModalLoading,
}) => {
  const isUpdate = !!selectedBarcode
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched } = form

  const handleOk = () => {
    const isBarcodeNewOrEdited = isFieldsTouched() || !isUpdate

    if (!isBarcodeNewOrEdited) {
      setVisibility(false)
      return null
    }

    if (isBarcodeNewOrEdited) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue(['value', 'product_name', 'categories', 'sorting_key'])
          const selectedCategoryIds = selectedCategories.map(category => category.id)

          const removedCategories = selectedCategoryIds.filter(cat => !includes(fieldsValue.categories, cat)).map((category) => {
            return {
              _delete: true,
              id: category,
            }
          })

          const newCategories = fieldsValue?.categories?.filter(cat => !includes(selectedCategoryIds, cat)).map(category => {
            return {
              id: category,
            }
          })
          const finalCategories = removedCategories.concat(newCategories)

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedBarcode.id : undefined

          onSubmit(actionType, { ...fieldsValue, categories: finalCategories, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelBarcodeModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateBarcodeSelenium' : 'addBarcodeSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update barcode' : 'Add a new barcode'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedBarcode.value : undefined}
          label="Barcode value"
          name="value"
          rules={[
            {
              message: 'Please add a barcode!',
              required: true,
            },
            {
              message: 'Barcode should contain only English letters and numbers!',
              pattern: new RegExp('^[A-Za-z0-9]+$'),
            },
          ]}
        >
          <Input disabled={isUpdate} />
        </Form.Item>
        <Form.Item
          initialValue={isUpdate ? selectedBarcode.product_name : undefined}
          label="Product name"
          name="product_name"
          rules={[
            {
              message: 'Product name is required!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={isUpdate ? selectedBarcode.sorting_key : undefined}
          label="Sorting key"
          name="sorting_key"
          rules={[{
            message: 'Please add sorting key!',
            required: true,
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Categories"
          name="categories"
        >
          <CategorySelector initialvalue={selectedCategories} />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

BarcodeModalForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedBarcode: barcodePropTypes,
  selectedCategories: PropTypes.array,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func.isRequired,
}

export const BarcodeModal = BarcodeModalForm
